import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Text, { Section } from "../../components/Theme";
import Img from "gatsby-image";
import Layout from "../../components/layout";
import Header from "../../components/Header";
import Footer from "../../components/footer.js";
import media from "styled-media-query";
import chevronRight from '../../images/icons/icon__chevron--white--right.svg';
import IntroSectionBackground from "../../images/background__bookshop2.jpeg";

// const PrintshopHeader = styled.div`
//     ${Grid};
//     min-height: 100vh;
// `

const IntroSection = styled.div`
    background-image: url(${IntroSectionBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    color: var(--white);

    Section {
        min-height: calc(60vh - 100px);
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-flow: column-reverse wrap;
        align-items: baseline;
        padding-bottom: 10vh;
    }

    .intro {
        &__category {
            text-transform: uppercase;
            border: 1px solid var(--white);
            padding: 4px 10px;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
`;


const FeaturedProducts = styled(Section)`
    /* background-color: #eee; */
`;

const ProductGrid = styled.div`
    grid-column: 2/12;
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-column-gap: 50px;
    ${media.lessThan("medium")`
        display: block;
        grid-column: 1/13;
    `}

    h2 {
        margin-bottom: 60px;
    }
`;
const ProductFeatured = styled.div`
    grid-column: span 2;
    grid-row: 1/2;
    margin-bottom: 0px;
`;
const ProductWrapper = styled.div`
    grid-column: span 1;
    grid-row: 2/3;
    margin-bottom: 120px;
`;
const FeaturedProductName = styled.h2`
    text-align: center;
    margin-bottom: 0;
`;

const NewsletterContainer = styled.div`
    grid-column: 1/12;
    padding: 0;
    max-width: 1640px;
    margin: auto;

    h3 {
        margin-top: 0;
    }
`;

const NewsletterParagraph = styled.p`
    color: var(--grey);
    font-family: var(--nhp);
    font-size: 18px;
    line-height: 1.2;
    margin: 20px 0;
`;

const SubscribeBox = styled.div`
    // grid-column: 2/11;
    // padding: 50px 0 0 0;

    input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0;
        width: 100%;
        border: 0px;
        background-color: #f1f1f1;
        background-repeat: no-repeat;
        background-position: 1.5rem center;
        height: 63px;
        font-size: 20px;
        padding: 1rem 2rem;
        grid-column: 1/13;

        &::placeholder {
            opacity: 0.3;
        }

        &[type="submit"] {
            border: none;
            background-size: 14px;
            background-position: calc(100% - 1rem) center;
            background-repeat: no-repeat;
            background-image: url(${chevronRight});
            background-color: var(--black);
            color: var(--white);
            width: 100%;
            min-width: 100px;
            max-width: 180px;
            text-align: left;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            height: 63px;
            font-size: 20px;
            line-height: 1;
        }
    }
`;

const SocialContainer = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NewsletterTitle = styled(Text)`
    margin-bottom: 20px;
    color: var(--black);
    font-family: var(--nhp);
    font-weight: 600;
    line-height: 1.2;
    width: 80%;

    ${media.lessThan("medium")`
        width: 100%;
        font-size: 1.4rem;
    `}    

    a {
        display: block;
        font-size: 11px;
        padding-top: 20px;
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }
`;
const BooksPage = ({ data }) => (
    <Layout parent="printshop-page">
        <div className="page-products">
            <Header content_color="black" />
            <IntroSection>
                {/* TODO: REPLACE WITH CMS FIELDS */}
                {/* TODO: ADD VIGNETTE LAYER */}
                <Section>
                    <div className="intro__content">
                        <Text ultra>
                            Bookshop
                        </Text>
                        {/*<Text body>
                            Perspectives on our planet and how it's being impacted
                            <br />
                            <br />
                        </Text>*/}
                    </div>
                </Section>
            </IntroSection>

            <FeaturedProducts small>
                <ProductGrid>
                    <ProductFeatured>
                        <Link to="/timelapse">
                            <div className="product-item">
                                <div className="gatsby-image-wrapper">
                                    <Img
                                        fluid={
                                            data.datoCmsBookLandingPage
                                                .featuredBookImage.fluid
                                        }
                                        alt=""
                                    />
                                </div>
                                <FeaturedProductName>
                                    Overview Timelapse:
                                    <br />
                                    How We Change The Earth
                                </FeaturedProductName>
                            </div>
                        </Link>
                    </ProductFeatured>
                    <ProductWrapper>
                        <Link to="/book">
                            <div className="product-item">
                                <div className="gatsby-image-wrapper">
                                    <Img
                                        fluid={
                                            data.datoCmsBookLandingPage
                                                .regularBookImage.fluid
                                        }
                                        alt=""
                                    />
                                </div>
                                <h3 className="product-name">
                                    Overview:
                                    <br />A New Perspective of Earth
                                </h3>
                            </div>
                        </Link>
                    </ProductWrapper>

                    <ProductWrapper>
                        <Link to="/kids-book">
                            <div className="product-item">
                                <div className="gatsby-image-wrapper">
                                    <Img
                                        fluid={
                                            data.datoCmsBookLandingPage
                                                .kidsBookImage.fluid
                                        }
                                        alt=""
                                    />
                                </div>
                                <h3 className="product-name">
                                    Overview, Young Explorer's Edition:
                                    <br />A New Way of Seeing Earth
                                </h3>
                            </div>
                        </Link>
                    </ProductWrapper>
                </ProductGrid>
            </FeaturedProducts>

            {/*<NewsletterSignup />*/}           
            <Section small className="subscribe-section">
                <NewsletterContainer>
                    <div className="grid-x grid-padding-x">
                        <div className="cell small-12 medium-6">
                            <SubscribeBox>
                                <NewsletterTitle sub>
                                    {(data.allDatoCmsHome.edges[0].node.ctaSection.sectionTitle) ? data.allDatoCmsHome.edges[0].node.ctaSection.sectionTitle : 'To receive our daily post and the occasional announcement:'}
                                </NewsletterTitle>
                                <form
                                    action="https://www.getrevue.co/profile/dailyoverview/add_subscriber"
                                    method="post"
                                    id="revue-form"
                                    name="revue-form"
                                    target="_blank"
                                    className="subscribe-section__form"
                                >
                                    <input
                                        className="revue-form-field"
                                        placeholder={(data.allDatoCmsHome.edges[0].node.ctaSection.emailPlaceholder) ? data.allDatoCmsHome.edges[0].node.ctaSection.emailPlaceholder : 'Email'}
                                        type="email"
                                        name="member[email]"
                                        id="member_email"
                                    />
                                    <input
                                        type="submit"
                                        value={(data.allDatoCmsHome.edges[0].node.ctaSection.emailSubmitButton) ? data.allDatoCmsHome.edges[0].node.ctaSection.emailSubmitButton : 'Submit'}
                                        name="member[subscribe]"
                                        id="member_submit"
                                        className="revue-form-submit"
                                    />
                                </form>
                                {/*<NewsletterParagraph>
                                    {(data.allDatoCmsHome.edges[0].node.ctaSection.newsletterInstructions) ? data.allDatoCmsHome.edges[0].node.ctaSection.newsletterInstructions : 'Subscribe to receive our best posts once a month'}
                                </NewsletterParagraph>*/}
                            </SubscribeBox>
                        </div>
                        <div className="cell small-12 medium-6">
                            <h3>{(data.allDatoCmsHome.edges[0].node.ctaSection.socialTitle) ? data.allDatoCmsHome.edges[0].node.ctaSection.socialTitle : 'Follow Overview on Social'}</h3>
                            {/*<NewsletterParagraph>
                                {(data.allDatoCmsHome.edges[0].node.ctaSection.socialLeadParagraph) ? data.allDatoCmsHome.edges[0].node.ctaSection.socialLeadParagraph : 'Join us on the social networks for the latest updates'}
                            </NewsletterParagraph>*/}
                            <SocialContainer>
                                <a className="newsletter-social newsletter-social__facebook"
                                   href="https://www.facebook.com/doverview"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   key="SocialContainer1"
                                >
                                    <img
                                        src={require("../../images/icons/icon__facebook.svg")}
                                        width="24"
                                        alt="Daily Overview"
                                        className="grid-right"
                                    />
                                </a>
                                <a className="newsletter-social newsletter-social__twitter"
                                   href="https://twitter.com/DOverview"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   key="SocialContainer2"
                                >
                                    <img
                                        src={require("../../images/icons/icon__twitter.svg")}
                                        width="24"
                                        alt="Daily Overview"
                                        className="grid-right"
                                    />
                                </a>
                                <a className="newsletter-social newsletter-social__instagram"
                                   href="https://www.instagram.com/dailyoverview/"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   key="SocialContainer3"
                                >
                                    <img
                                        src={require("../../images/icons/icon__instagram.svg")}
                                        width="24"
                                        alt="Daily Overview"
                                        className="grid-right"
                                    />
                                </a>
                                <a className="newsletter-social newsletter-social__amazon"
                                   href="https://www.amazon.com/Benjamin-Grant/e/B01HMX7AZU/"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   key="SocialContainer4"
                                >
                                    <img
                                        src={require("../../images/icons/icon__amazon.svg")}
                                        width="24"
                                        alt="Daily Overview"
                                        className="grid-right"
                                    />
                                </a>
                            </SocialContainer>
                        </div>
                    </div>
                </NewsletterContainer>
            </Section>            
        </div>
        <Footer />
    </Layout>
);

export default BooksPage;

export const query = graphql`
    query BooksPageQuery {
        allDatoCmsHome {
           edges {
               node {
                    ctaSection {
                        emailPlaceholder
                        emailSubmitButton
                        newsletterInstructions
                        sectionTitle
                        socialLeadParagraph
                        socialTitle
                    }
                }
            }
        }
        datoCmsBookLandingPage {
            featuredBookImage {
                fluid(
                    maxWidth: 1200
                    imgixParams: {
                        dpr: 1
                        w: "900"
                        fit: "clip"
                        auto: "compress"
                    }
                ) {
                    ...GatsbyDatoCmsFluid
                }
            }

            kidsBookImage {
                fluid(
                    maxWidth: 900
                    imgixParams: {
                        dpr: 1
                        w: "900"
                        fit: "clip"
                        auto: "compress"
                    }
                ) {
                    ...GatsbyDatoCmsFluid
                }
            }
            regularBookImage {
                fluid(
                    maxWidth: 700
                    imgixParams: {
                        dpr: 1
                        w: "700"
                        fit: "clip"
                        auto: "compress"
                    }
                ) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }
`;
